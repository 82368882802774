import React, { Context, createContext, useCallback, useMemo, useReducer, useRef, useState } from 'react'

/**
 * Creates context from function returning default properties. It returns a typle of Context & Hook to be used in
 * provider. The value for context is a `[context, setContext]`, the setter merges values partially. That tuple retains
 * stable identity if context was not changed, so it can be fed directly fed to <Context.Provider> directly
 *
 * Big difference between this and regular providers, is that this hook retains reference to context object, while still
 * creating new identity on change. This behaves similar to SDK models, by creating a proxy wrapper on change. This is
 * really useful for accessing up to date values in cached callbacks.
 */
export function createContextProvider<T extends (...args: any[]) => any>(getDefaults: T, variableName: string) {
  type ConstructedContext = ReturnType<typeof getDefaults>
  type ConstructedContextWithSetter = [
    context: ConstructedContext,
    setter: (props: Partial<ConstructedContext> | ((props: ConstructedContext) => Partial<ConstructedContext>)) => void
  ]
  const ConstructedContext = (((window as any)[variableName] as any as Context<ConstructedContextWithSetter>) ||=
    createContext([getDefaults(), (props: Partial<ConstructedContext>) => {}] as ConstructedContextWithSetter))

  const useConstructedContextProvider = (props?: Partial<T>) => {
    const ref = useRef<ConstructedContext>()
    if (ref.current == null) {
      ref.current = {
        ...getDefaults(),
        ...props
      }
    }

    const updateContext = useCallback((newState: Partial<T> | ((props: T) => Partial<T>)) => {
      if (typeof newState == 'function') newState = newState(ref.current)
      for (var property in newState) {
        if (newState[property] !== ref.current[property]) {
          Object.assign(ref.current, newState)
          setNewContextWithSetter(createNewIdentity)
          break
        }
      }
    }, [])

    function createNewIdentity(): ConstructedContextWithSetter {
      return [new Proxy(ref.current, {}), updateContext]
    }
    const [ConstructedContextWithSetter, setNewContextWithSetter] =
      useState<ConstructedContextWithSetter>(createNewIdentity)

    return ConstructedContextWithSetter
  }

  return [ConstructedContext, useConstructedContextProvider] as [
    typeof ConstructedContext,
    typeof useConstructedContextProvider
  ]
}
