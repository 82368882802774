import { DependencyList, useCallback, useContext } from 'react'
import { Confirmation, ConfirmationContext } from '../components/providers/ConfirmationProvider.js'

const useConfirmation = (action: () => void, confirmationProps: Confirmation, deps: DependencyList = []) => {
  const { setConfirm } = useContext(ConfirmationContext)

  const callback = useCallback(
    (noConfirmation = false) => {
      if (noConfirmation) {
        action()

        return
      }

      setConfirm({ ...confirmationProps, action })
    },
    [setConfirm, ...deps]
  )

  return confirmationProps == null ? null : callback
}

export { ConfirmationContext }
export default useConfirmation
