import { ReactElement, ReactNode } from 'react'

export type SlotedChildren<T extends any[], S extends string = string> =
  | ReactNode
  | ((...args: T) => SlotedChildren<T, S>)
  | Partial<Record<S, (...args: T) => SlotedChildren<T, S>>>
  | Slots<S>
export type Slots<S extends string = string> = Partial<Record<S, any>>

/**
 * Groups children by slot property, uses `children` as default slot invokes callback with the object where elements are
 * indexed by slot children that are functions will be invoked with given arguments.
 *
 * The hook supports multiple elements per slot, thus outputting them as array. This makes React expect `key` property
 * defined on each slotted element. If you know that the component only needs one element per slot, you can use
 * `{slotName?.[0]}` syntax when rendering the element, which will make the key unnecessary.
 *
 * @example
 *
 *   // Simpliest case, equivalent to not using any slots
 *   function Parent({ children: any }) {
 *     return useSlot(children, ({ children }) => children)
 *   }
 *
 * @example
 *
 * // Typed callback argument, using `name` slot
 *   function Parent({ children: SlottedChildren<[style: Style.Rule]> }) {
 *   return useSlot(children, ({name}) => name, style)
 *   }
 *   // Elsewhere, the `style` is now typed as Style
 *   <Parent>{{name: (style) => style.details.name}}</Parent>
 *
 * @example
 *
 * // Use three slots, compatible with following examples:
 *   function Parent({children: SlottedChildren<[arg: string]>}) {
 *   return useSlots(
 *   children,
 *   {({before, children, after}) => <Box>
 *   {before}
 *   <Box>{children}</Box>
 *   {after}
 *   </Box>,
 *   'arg-given-to-callback'
 *   }
 *   ),
 *   }
 *
 * @example
 *
 * // Following are equivalent examples
 *   // Using function with argument to instantiate slotted Record
 *   <Parent>{(arg) => ({left: <Left>, right: [<Right />, <Right 2 />], children: <Children>})}</Parent>
 *
 * @example
 *
 * // Multiple slotted Record
 *   <Parent>{[{left: <Left>}, {right: [<Right />, <Right 2 />]}, <Children>}]}</Parent>
 *
 * @example
 *
 * // Single slotted Record with function calls
 *   <Parent>{{left: <Left>, right: (arg) => [<Right />, <Right 2 />], children: <Children>}}</Parent>
 *
 * @example
 *
 * // Mixed object notations, unslotted children all merged together
 *   <Parent>{{left: (arg) => <Left>,
 *   right: [<Right />]}}
 *   {(arg) => <Right2 slot="right" />}
 *   <Children>
 *   </Parent>
 *
 * @example
 *
 * // Using slot property on react node (unlike HTML/Chakra elements, custom components need `slot` prop defined)
 *   <Parent><Left slot="left"><Right slot="right"><Right2 slot="right"><Children slot="children"></Parent>
 *
 */

export function useSlots<T extends any[], S extends string = string>(
  children: SlotedChildren<T, S> | ReactNode,
  render: (slots: Slots<S>) => ReactElement,
  ...args: T
) {
  // turn children into {slotName: [Child]} format
  function mapChild(child: SlotedChildren<T>): Slots {
    if (typeof child == 'function') {
      return mapChild(child(...args))
    } else if (Array.isArray(child)) {
      return child.map(mapChild).reduce(mergeSlots, {})
    } else if (typeof child == 'object' && child) {
      if ('props' in child) {
        // React elements can specify slot in `slot` property
        return { [child.props.slot || 'children']: [child] }
      } else {
        // objects are interpreted as children indexed by slot name
        var slots = {} as Slots
        for (var property in child) {
          slots[property] = Object.values(mapChild(child[property as keyof typeof child])).flat()
        }
        return slots
      }
    } else {
      return { children: [child] }
    }
  }

  // Merge all slotted children together
  function mergeSlots(result: Slots, slots: Slots) {
    if (slots != null)
      for (var slot in slots) {
        Object.assign(result, { [slot]: (result[slot] || []).concat(slots[slot]) })
      }
    return result
  }

  const slots = [children].flat().map(mapChild).reduce(mergeSlots, {})
  for (var property in slots)
    slots[property as keyof typeof slots] = slots[property as keyof typeof slots].map((e: any, index: number) => {
      if (e != null && typeof e == 'object' && 'props' in e && e.key == null && e.props.key == null) {
        return { ...e, key: property + '-' + index }
      } else {
        return e
      }
    })

  return render(slots)
}
