export interface Appstrinsic {
  (...args: any[]): any
  q?: any[],
  p?: string
}

export default class Gainsight {
  static setScript(tag: string){

    window.aptrinsic ||= function() {
      (window.aptrinsic.q=window.aptrinsic.q||[]).push(arguments)
    };
    window.aptrinsic.p = tag
    document.head.appendChild(Object.assign(document.createElement("script"), {
      id: 'gainsight',
      async: true,
      src:`https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js?a=${tag}`
    }))
  }
  static init(userData: {userFields: Record<string, string>, accountFields: Record<string, string>}, globalContext: Record<string, string>){
    const w = window as any

    w.aptrinsic('identify', userData.userFields, userData.accountFields)
    w.aptrinsic('set', 'globalContext', globalContext);
  }
}
