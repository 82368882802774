import { createContext } from 'react'

const defaults = {
  logLevel: 'verbose',
  auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN,
  auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  cdn: import.meta.env.VITE_CDN_URL,
  backend: import.meta.env.VITE_FRONTEND_URL + '/api',
  frontend: import.meta.env.VITE_FRONTEND_URL,
  inventory: import.meta.env.VITE_INVENTORY_API,
  formsBackend: import.meta.env.VITE_FORMS_URL || 'https://forms-entities-{region}-staging.sitecore-staging.cloud',
  formsFrontend: import.meta.env.VITE_FORMS_API || 'https://forms-staging.sitecore-staging.cloud',
  inventoryEnv: import.meta.env.VITE_INVENTORY_ENV,
  xmCloudDashboard: import.meta.env.VITE_XM_CLOUD_DASHBOARD_URL,
  xmCloudSystemId: import.meta.env.VITE_XM_CLOUD_SYSTEM_ID,
  contentHubOneSystemId: import.meta.env.VITE_CONTENT_HUB_ONE_SYSTEM_ID,
  sitecorePostfix: import.meta.env.VITE_SITECORE_URL_POSTFIX,
  apmServer: 'https://weu-i1-apm-001.stylelabs.io',
  gainsightTag: 'AP-BR1SACAOWQ2G-2-4',
  pluginsWebsite: 'https://feaas-marketplace-website.vercel.app'
}

export type EnvironmentName = 'localhost' | 'development' | 'pre-production' | 'production' | 'staging' | 'qa' | 'ci'
export type EnvironmentVariables = Record<keyof typeof defaults, string>

export const environments: Record<EnvironmentName, EnvironmentVariables> = {
  localhost: defaults,
  ci: defaults,
  development: {
    logLevel: 'verbose',
    auth0Domain: 'auth-staging-1.sitecore-staging.cloud',
    auth0ClientId: 'gfxfawwHDrRPWCB8b2idLOwAAomyONjt',
    auth0Audience: 'https://api-staging.sitecore-staging.cloud',
    cdn: `https://feaasdev.blob.core.windows.net`,
    backend: 'https://components-dev.sitecore-staging.cloud/api',
    frontend: 'https://components-dev.sitecore-staging.cloud',
    formsFrontend: 'https://forms-staging.sitecore-staging.cloud',
    formsBackend: 'https://forms-entities-{region}-staging.sitecore-staging.cloud',
    inventory: 'https://platform-inventory-staging.sitecore-staging.cloud',
    inventoryEnv: 'staging',
    xmCloudDashboard: 'https://xmapps-staging.sitecore-staging.cloud/',
    xmCloudSystemId: '8DB0AD22-445F-43FB-8D8E-F23C9396C974',
    contentHubOneSystemId: '22ED2CEB-58F9-4388-A04D-1CD7004CF818',
    sitecorePostfix: 'sitecore-staging.cloud',
    apmServer: 'https://weu-i1-apm-001.stylelabs.io',
    gainsightTag: '',
    pluginsWebsite: 'https://feaas-marketplace-website.vercel.app'
  },
  'pre-production': {
    logLevel: 'verbose',
    auth0Domain: 'auth-beta.sitecorecloud.io',
    auth0ClientId: 'lMR1JKdcJl2jCVuaLgAqvGzEydvXm5QU',
    auth0Audience: 'https://api-beta.sitecorecloud.io',
    cdn: `https://feaasbeta.blob.core.windows.net`,
    backend: 'https://components-beta.sitecorecloud.io/api',
    frontend: 'https://components-beta.sitecorecloud.io',
    formsFrontend: 'https://forms-preprod.sitecorecloud.io',
    formsBackend: 'https://forms-entities-{region}-preprod.sitecorecloud.io',
    inventory: 'https://platform-inventory-beta.sitecorecloud.io',
    inventoryEnv: 'pre-production',
    xmCloudDashboard: 'https://xmapps-beta.sitecorecloud.io/',
    contentHubOneSystemId: '176037FA-44B8-4EB7-A3AF-DC0AFD38B68D',
    xmCloudSystemId: '92E5CAD3-7AEC-4FCE-88BC-6013F5FD9869',
    sitecorePostfix: 'sitecorecloud.io',
    apmServer: 'https://weu-e1-apm-001.stylelabs.io',
    gainsightTag: '',
    pluginsWebsite: 'https://feaas-marketplace-website.vercel.app'
  },
  production: {
    logLevel: 'silent',
    auth0Domain: 'auth.sitecorecloud.io',
    auth0ClientId: 'YOcd1Oh677U6opSjgzrlOpZlwMFBrAk0',
    auth0Audience: 'https://api.sitecorecloud.io',
    cdn: `https://feaas.blob.core.windows.net`,
    backend: 'https://components.sitecorecloud.io/api',
    frontend: 'https://components.sitecorecloud.io',
    inventory: 'https://platform-inventory.sitecorecloud.io',
    formsFrontend: 'https://forms.sitecorecloud.io',
    formsBackend: 'https://forms-entities-{region}.sitecorecloud.io',
    inventoryEnv: 'production',
    xmCloudDashboard: 'https://xmapps.sitecorecloud.io',
    xmCloudSystemId: '5907637C-CDDF-48E9-ACEF-BD06F1A6BAB8',
    contentHubOneSystemId: 'CE4FE52C-500B-41A9-A41E-42AD034836A6',
    sitecorePostfix: 'sitecorecloud.io',
    apmServer: 'https://weu-e1-apm-001.stylelabs.io',
    gainsightTag: 'AP-BR1SACAOWQ2G-2',
    pluginsWebsite: 'https://feaas-marketplace-website.vercel.app'
  },
  staging: {
    logLevel: 'verbose',
    auth0Domain: 'auth-staging-1.sitecore-staging.cloud',
    auth0ClientId: 'gfxfawwHDrRPWCB8b2idLOwAAomyONjt',
    auth0Audience: 'https://api-staging.sitecore-staging.cloud',
    cdn: `https://feaasstaging.blob.core.windows.net`,
    backend: 'https://components-staging.sitecore-staging.cloud/api',
    frontend: 'https://components-staging.sitecore-staging.cloud',
    inventory: 'https://platform-inventory-staging.sitecore-staging.cloud',
    formsFrontend: 'https://forms-staging.sitecore-staging.cloud',
    formsBackend: 'https://forms-entities-{region}-staging.sitecore-staging.cloud',
    inventoryEnv: 'staging',
    xmCloudDashboard: 'https://xmapps-staging.sitecore-staging.cloud/',
    xmCloudSystemId: '8DB0AD22-445F-43FB-8D8E-F23C9396C974',
    contentHubOneSystemId: '22ED2CEB-58F9-4388-A04D-1CD7004CF818',
    sitecorePostfix: 'sitecore-staging.cloud',
    apmServer: 'https://weu-i1-apm-001.stylelabs.io',
    gainsightTag: 'AP-BR1SACAOWQ2G-2-2',
    pluginsWebsite: 'https://feaas-marketplace-website.vercel.app'
  },
  qa: {
    logLevel: 'verbose',
    auth0Domain: 'auth-staging-1.sitecore-staging.cloud',
    auth0ClientId: 'gfxfawwHDrRPWCB8b2idLOwAAomyONjt',
    auth0Audience: 'https://api-staging.sitecore-staging.cloud',
    cdn: `https://feaasqa.blob.core.windows.net`,
    backend: 'https://components-qa.sitecore-staging.cloud/api',
    frontend: 'https://components-qa.sitecore-staging.cloud',
    inventory: 'https://platform-inventory-staging.sitecore-staging.cloud',
    formsFrontend: 'https://forms-staging.sitecore-staging.cloud',
    formsBackend: 'https://forms-entities-{region}-staging.sitecore-staging.cloud',
    inventoryEnv: 'staging',
    xmCloudDashboard: 'https://xmapps-staging.sitecore-staging.cloud/',
    xmCloudSystemId: '8DB0AD22-445F-43FB-8D8E-F23C9396C974',
    contentHubOneSystemId: '22ED2CEB-58F9-4388-A04D-1CD7004CF818',
    sitecorePostfix: 'sitecore-staging.cloud',
    apmServer: 'https://weu-i1-apm-001.stylelabs.io',
    gainsightTag: 'AP-BR1SACAOWQ2G-2-3',
    pluginsWebsite: 'https://feaas-marketplace-website.vercel.app'
  }
}

export function getEnvironment(): EnvironmentName {
  if ('Cypress' in window) {
    return 'ci'
  } else if (location.hostname.match(/(pages|components)-staging\./)) {
    return 'staging'
  } else if (location.hostname.match(/(pages|components)-(beta|preprod)\./)) {
    return 'pre-production'
  } else if (location.hostname.match(/(pages|components)-qa\./)) {
    return 'qa'
  } else if (location.hostname.match(/(pages|components)\./)) {
    return 'production'
  } else if (location.hostname.match(/(pages|components)-dev\./)) {
    return 'development'
  } else {
    return 'localhost'
  }
}

export const EnvironmentContext = createContext<EnvironmentVariables>(null)
