import { useAuth0, User } from '@auth0/auth0-react'
import { useToast } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { AuthError, AuthModel, SDK, TenantModel } from '@sitecore-feaas/sdk'
import { useContext, useEffect, useMemo } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { createContextProvider } from '../../hooks/useContextProvider.js'
import type { CustomNavigateFunction } from '../../hooks/useWindowQueryUpdate.js'
import { EnvironmentContext, EnvironmentVariables } from './EnvironmentProvider.js'
import { QueryStringContext } from './QueryStringProvider.js'
export { SDK }
export type SDKStatus = 'loading' | 'authenticating' | 'fetching' | 'ready' | 'failed'
export type SDKStatusCallback = (status: SDKStatus, sdk?: SDK) => void
export const [SDKContext, useSDKContextProvider] = createContextProvider(
  () => ({
    sdk: null as SDK,
    tenant: null as TenantModel,
    navigate: null as CustomNavigateFunction,
    setStatus: null as SDKStatusCallback,
    status: 'loading' as SDKStatus,
    env: {} as EnvironmentVariables
  }),
  '_FEAAS_SDK_CONTEXT'
)
declare global {
  interface Window {
    feaasSDK: SDK
    feaasSDKLoading: Promise<SDK>
  }
}

interface Props {
  children: any
  navigate?: CustomNavigateFunction
  onStatusChange?: SDKStatusCallback
  backend?: string
  frontend?: string
  auth?: Partial<AuthModel>
  user?: User
}

export default function SDKProvider({
  children,
  navigate,
  onStatusChange,
  auth: externalAuth,
  backend,
  frontend
}: Props) {
  const env = useContext(EnvironmentContext)
  const [query, setQuery] = useContext(QueryStringContext)
  const toast = useToast()
  const auth0 = useAuth0()
  const handleError = useErrorHandler()
  /** Init SDK */
  const sdk = useMemo(() => {
    return new SDK({
      cdn: env.cdn,
      backend: backend ?? env.backend,
      frontend: frontend ?? env.frontend,
      inventory: env.inventory,
      inventoryEnv: env.inventoryEnv,
      sitecorePostfix: env.sitecorePostfix,
      formsBackend: env.formsBackend,
      verbose: env.logLevel == 'verbose',
      useProxy: true,
      onError: (e: Error, message: string) => {
        requestAnimationFrame(() => {
          toast({
            isClosable: true,
            duration: 4000,
            status: 'error',
            title: message,
            description: e.message
          })
        })
        // @ts-ignore
        throw new Error(message, { cause: e })
      }
    })
  }, [])

  useMemo(() => {
    sdk.auth.getDefaultContext = () => ({
      systemId: env.xmCloudSystemId
    })
    SDK.ExternalComponent.updateComponents(
      sdk.renderingHost.registeredComponents,
      sdk.renderingHost.expandedComponents,
      SDK.ExternalComponent.loadFromSessionStorage() || []
    )
    SDK.Datasource.updateExternalDatasources(
      sdk.renderingHost.registeredDatasources,
      SDK.Datasource.loadFromSessionStorage() || []
    )
  }, [sdk])

  window.feaasSDK = sdk
  const { auth } = sdk

  // set synchronously initially to avoid re-renders
  //if (!auth.logout) Object.assign(auth, externalAuth || auth0)
  useEffect(() => {
    auth.set(externalAuth || auth0)
  }, [auth0, externalAuth])

  const sdkContextWithSetter = useSDKContextProvider({ sdk, env, navigate })
  const [{ status }, setContext] = sdkContextWithSetter

  useEffect(() => {
    onStatusChange?.(status, sdk)
  }, [status])

  /** Handle Login */
  useEffect(() => {
    auth.login(query).catch((e) =>
      handleError(() => {
        if (e.message.includes('is not part of') && e.message.includes('organization')) {
          // handle invalid last organization login
          AuthModel.setLocalStorageContext(null)
          return
        } else if (e.message.includes('user_tenant_mismatch')) {
          // handle user tenant mismatch
          AuthModel.setLocalStorageContext(null)
          requestAnimationFrame(() => {
            return toast({
              isClosable: true,
              status: 'error',
              title: 'User tenant mismatch',
              description:
                'You cannot access the requested tenant with that user. You will be redirected to tenant selection screen to choose from your available tenants'
            })
          })
          setQuery({})
          // NOTE: Not sure if we need to check for window. Added this in case we might use this outside of browser context
          if (typeof window !== 'undefined') {
            setTimeout(() => {
              window.location.href = window.location.origin
            }, 4000)
          }
          return
        } else {
          // @ts-ignore: TS.50 doesnt like cause
          return new AuthError('Could not authenticate user to use the specified resources', { cause: e })
        }
      })
    )
  }, [auth, auth.isLoading, auth.isAuthenticated, query.organization])

  /** Preflight runs on every SDK request allowing to refresh token */
  useEffect(() => {
    if (auth.isAuthenticated) {
      Sentry.setUser({
        id: auth.getUser()?.id
      })
      auth.initPreflight(query, env.auth0Audience)
    }
  }, [sdk, auth.getAccessTokenSilently, auth.isAuthenticated])

  useEffect(() => {
    if (!location.search.includes('organization=') && auth.isAuthenticated) {
      setQuery(
        {
          ...query,
          ...auth.getContext(query)
        },
        {
          replace: true,
          silent: true
        }
      )
    }
  }, [location.pathname, location.search])

  if (location.pathname === '/logout' && auth.logout) {
    auth.logout({ returnTo: window.location.origin })
    return null
  }
  return <SDKContext.Provider value={sdkContextWithSetter}>{children}</SDKContext.Provider>
}
