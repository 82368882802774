import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { HTTPError, LibraryError, LibraryModel, TenantError } from '@sitecore-feaas/sdk'
import { useSDK } from '../../hooks/useData.js'
import { QueryStringContext } from './QueryStringProvider.js'
import qs from 'query-string'
import { useErrorHandler } from 'react-error-boundary'
import { SDKContext } from './SDKProvider.js'
import { EnvironmentContext } from './EnvironmentProvider.js'
import Gainsight from '../../utils/gainsight.js'

export type LibraryContext = [LibraryModel, (library: LibraryModel) => void]
export const LibraryContext = createContext<LibraryContext>([null, null])

interface Props {
  libraryId: string
  children: any
}
const LibraryProvider = ({ libraryId, children }: Props) => {
  const [{ sdk, navigate, status }, setContext] = useContext(SDKContext)
  const [query, setQuery] = useContext(QueryStringContext)
  const { isAuthenticated, tenant } = useSDK('auth')
  const renderingHost = useSDK('renderingHost')
  const handleError = useErrorHandler()
  const env = useContext(EnvironmentContext)

  const [library, setLibrary] = useState<LibraryModel>()

  libraryId ||= tenant?.getProjectId()

  // retrieve tenant and user claims
  useEffect(() => {
    if (isAuthenticated) {
      sdk.auth
        .findTenant(
          query,
          sdk.auth.getContext({}).tenantName?.startsWith('hc-') ? env.contentHubOneSystemId : env.xmCloudSystemId,
          libraryId
        )
        .then((tenant) => {
          sdk.auth.set({ tenant })
          renderingHost.fetchURL()
          sdk.auth.retrieveClaims()
        })
        // @ts-ignore: TS.50 doesnt like cause
        .catch((e) => {
          handleError(new TenantError('Could not validate user access to components ', { cause: e }))
        })
    }
  }, [isAuthenticated])

  // update query string with tenant information
  useEffect(() => {
    if (tenant) {
      setQuery(
        {
          ...query,
          ...tenant.getContext()
        },
        { replace: true }
      )
    }
  }, [tenant])

  // fetch org libraries for library selector
  useEffect(() => {
    if (tenant) {
      sdk.libraries.fetch()
    }
  }, [tenant])

  useEffect(() => {
    if (library && libraryId && library.id != libraryId) {
      setContext({ status: 'fetching' })
      setLibrary(null)
      ;(document.querySelector('feaas-context')?.shadowRoot || document)
        ?.querySelector('feaas-loader-app, feaas-loader')
        ?.removeAttribute('hidden')
    }
  }, [libraryId, library])

  // get library information
  useEffect(() => {
    if (!libraryId || !tenant) return
    ;(async () => {
      const library = sdk.libraries.construct({
        id: libraryId
      })
      library.decorate(navigate)
      // Display loading splash screen until all data is preloaded
      setContext({ status: 'fetching' })
      try {
        sdk.library = library
        await library.get()
        await library.fetchAll(true)
        // already switched library? dont invoke callbacks.
        if (library.id != sdk.library.id) {
          return
        }
        setLibrary(library)
        setContext({ status: 'ready' })
      } catch (e) {
        const httpError = e.cause as unknown as HTTPError
        if (httpError?.status == 404) {
          handleError(new LibraryError('Could not reach backend server', { cause: httpError }))
        } else if (httpError?.status == 500) {
          handleError(new LibraryError('Server has internal error', { cause: httpError }))
        } else {
          handleError(new LibraryError('Could not access specified library', { cause: e.cause || e }))
        }
        setContext({ status: 'failed' })
      }
    })()
  }, [tenant, libraryId])

  useEffect(() => {
    if (status == 'ready') {
      // all urls should start from /library/libraryId
      if (!location.pathname.startsWith(library.getPath()) && location.pathname != '/error') {
        navigate({ pathname: library.getPath(), search: `?${qs.stringify(query)}` }, { replace: true })
      }
      ;(document.querySelector('feaas-context')?.shadowRoot || document)
        ?.querySelector('feaas-loader-app, feaas-loader')
        ?.setAttribute('hidden', 'hidden')
    }
  }, [status])

  useEffect(() => {
    Gainsight.setScript(env.gainsightTag)
  }, [])

  // init gainsight
  useEffect(() => {
    if (!sdk.auth.claims || status !== 'ready') return
    Gainsight.init(sdk.auth.getGainsightUserData(), sdk.auth.getGainsightGlobalContext())
  }, [status, sdk.auth.claims])

  const contextValues = useMemo<LibraryContext>(() => [library, setLibrary], [library])
  if (!library || (library && library.id != libraryId && libraryId)) {
    return null
  }

  if (status != 'ready') {
    return null
  }
  return <LibraryContext.Provider value={contextValues}>{children}</LibraryContext.Provider>
}

export default LibraryProvider
