import SDK, { LibraryModel, SDKCollection, SDKModel, SDKModelSubclass } from '@sitecore-feaas/sdk'
import { SDKContext } from '../components/providers/SDKProvider.js'
import { useContext, useEffect, useMemo, useState } from 'react'
import { LibraryContext } from '../components/providers/LibraryProvider.js'

/** Retrieve SDK itself or its property by path */
export function useSDK<K extends string | void>(
  path?: K extends ValidatePath<SDK, K> ? K : ValidatePath<SDK, K>
): K extends void ? SDK : DeepIdx<SDK, K> {
  const sdk = useContext(SDKContext)[0].sdk
  if (path !== undefined) return useData(sdk, path as any) as any
  return sdk as any
}

/** Retrieve Library itself or its property by path */
export function useLibrary<K extends string | void>(
  path?: K extends ValidatePath<LibraryModel, K> ? K : ValidatePath<LibraryModel, K>
): K extends void ? LibraryModel : DeepIdx<LibraryModel, K> {
  const library = useContext(LibraryContext)[0]
  if (path !== undefined) return useData(library, path as any) as any
  return useModelObserver(library) as any
}

export function useData<T extends object, K extends string>(
  object: T,
  path: K extends ValidatePath<T, K> ? K : ValidatePath<T, K>
): DeepIdx<T, K> {
  return useModelObserver(
    path.split('.').reduce((result: any, key) => {
      if (result !== undefined && result[key]) {
        return result[key]
      }
      return undefined
    }, object)
  ) as any
}

type DeepIdx<T, K extends string | void> = K extends keyof T
  ? T[K]
  : K extends `${infer K0}.${infer KR}`
  ? K0 extends keyof T
    ? DeepIdx<T[K0], KR>
    : never
  : never

type ValidatePath<T, K extends string | void> = K extends keyof T
  ? K
  : K extends `${infer K0}.${infer KR}`
  ? K0 extends keyof T
    ? `${K0}.${ValidatePath<T[K0], KR>}`
    : Extract<keyof T, string>
  : Extract<keyof T, string>

export function useModelObserver<T extends SDKCollection | SDKModel>(model: T, defaultValue = undefined as any) {
  function getNewIdentity(d: T) {
    return d?.proxy() as T
  }

  const [result, setResult] = useState<T>(() => getNewIdentity(model))
  useEffect(() => {
    setResult(model)

    if (model != null) {
      const setter = (data: any) => {
        setResult(getNewIdentity(data))
      }

      model.observe(setter)
      return () => model.unobserve(setter)
    }
  }, [model])

  return result || (defaultValue as T)
}
