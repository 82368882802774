import { parse, ParsedQuery, ParseOptions, stringify, StringifyOptions } from 'query-string'
import { useCallback, useMemo } from 'react'
import { To, useNavigate } from 'react-router'

export interface CustomNavigateFunction {
  (to: To, options?: CustomNavigateOptions): void
}
export interface CustomNavigateOptions {
  replace?: boolean
  state?: any
  silent?: boolean
}
export type QueryStringResult<T> = [
  ParsedQuery<T>,
  (query: Record<string, any>, options?: CustomNavigateOptions) => void
]

const defaultStringifyOptions: StringifyOptions = {
  skipNull: true
}
const defaultParseOptions: ParseOptions = {
  parseNumbers: false,
  parseBooleans: false
}

// forked from https://github.com/trevorblades/use-query-string/blob/main/src/index.ts
// rewritten to always parse query string, to play well with next.js
// original version only parsed one and stuck with internal state

export function useQueryString(location: Location, navigate: CustomNavigateFunction): QueryStringResult<any> {
  const state: ParsedQuery = useMemo(() => {
    return parse(location.search, defaultParseOptions)
  }, [location.search])

  const setQuery = useCallback(
    (values: ParsedQuery | ((ParsedQuery: ParsedQuery) => ParsedQuery), options?: CustomNavigateOptions): void => {
      const nextState = typeof values === 'function' ? values(state) : values
      navigate(
        location.pathname +
          '?' +
          stringify(
            {
              ...state,
              ...nextState
            },
            defaultStringifyOptions
          ),
        options
      )
    },
    [state]
  )

  return [state, setQuery]
}

const useWindowQueryUpdate = (navigate?: CustomNavigateFunction): QueryStringResult<any> => {
  const navigateTo = navigate || useNavigate()
  const [query, setQuery] = useQueryString(window.location, navigateTo)
  return [query, setQuery]
}

export default useWindowQueryUpdate
