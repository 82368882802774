import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useDisclosure
} from '@chakra-ui/react'
import React, { createContext, MouseEvent, useEffect, useRef, useState } from 'react'

export interface Confirmation {
  type?: string
  body: any
  title: string | JSX.Element
  button: string | JSX.Element
  bypass?: boolean
  action?: (e: MouseEvent<HTMLButtonElement>) => void
  variant?: 'danger'
  cancelRef?: React.MutableRefObject<undefined>
}

export const ConfirmationContext = createContext({
  setConfirm: null as (confirmation: Confirmation) => any
})

const ConfirmationProvider = ({ children, ...portalProps }: { children: React.ReactNode } & Partial<ModalProps>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [confirm, setConfirm] = useState<Confirmation>(null)

  const cancelRef = useRef()

  useEffect(() => {
    if (confirm) {
      if (confirm.bypass) {
        confirm.action(null)
        setConfirm(null)
      } else {
        onOpen()
      }
    } else {
      onClose()
    }
  }, [confirm])

  const close = () => {
    setConfirm(null)
    onClose()
  }

  const isModal = confirm?.type == 'modal'
  const isDangerVariant = confirm?.variant == 'danger'

  const Dialog = isModal ? Modal : AlertDialog
  const Overlay = isModal ? ModalOverlay : AlertDialogOverlay
  const Content = isModal ? ModalContent : AlertDialogContent
  const Header = isModal ? ModalHeader : AlertDialogHeader
  const Body = isModal ? ModalBody : AlertDialogBody
  const Footer = isModal ? ModalFooter : AlertDialogFooter
  const maxWidth = isModal ? '4xl' : 'md'

  const onConfirm = (e: MouseEvent<HTMLButtonElement>) => {
    confirm?.action(e)

    if (!e.isDefaultPrevented?.()) close()
  }

  return (
    <ConfirmationContext.Provider value={{ setConfirm }}>
      {children}

      <Dialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close} isCentered={false} {...portalProps}>
        <Overlay
          className='confirm-dialog'
          onPointerMove={(e: Event) => {
            if (isOpen) e.stopPropagation()
          }}
          onMouseMove={(e: Event) => {
            if (isOpen) e.stopPropagation()
          }}
          onPointerDown={(e: Event) => {
            if (isOpen) e.stopPropagation()
          }}
        >
          <Content maxWidth={maxWidth} className='dialog-content' maxHeight='calc(100vh - 100px)' minHeight='1px'>
            {isModal && <ModalCloseButton />}

            <Header fontSize='lg' fontWeight='bold'>
              {confirm?.title}
            </Header>

            <Body>{confirm?.body}</Body>

            <Footer>
              <Button ref={confirm?.cancelRef || cancelRef} onClick={close} size='sm' variant='minimal'>
                {isModal ? 'Close' : 'Cancel'}
              </Button>

              {confirm?.button && (
                <>
                  {isModal && (
                    <Button variant='primary' colorScheme='primary' onClick={onConfirm} ml={3}>
                      {confirm?.button}
                    </Button>
                  )}

                  {isDangerVariant && (
                    <Button variant='solid' size='sm' colorScheme='red' onClick={onConfirm} ml={3}>
                      {confirm?.button}
                    </Button>
                  )}

                  {!isDangerVariant && (
                    <Button variant='primary' onClick={onConfirm} ml={3}>
                      {confirm?.button}
                    </Button>
                  )}
                </>
              )}
            </Footer>
          </Content>
        </Overlay>
      </Dialog>
    </ConfirmationContext.Provider>
  )
}

export default ConfirmationProvider
