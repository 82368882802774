import React, { createContext } from 'react'
import useWindowQueryUpdate, {
  CustomNavigateFunction,
  CustomNavigateOptions
} from '../../hooks/useWindowQueryUpdate.js'

export const QueryStringContext = createContext<ReturnType<typeof useWindowQueryUpdate>>(null)

const QueryStringProvider = ({ children, navigate }: { children: any; navigate?: CustomNavigateFunction }) => {
  const [query, setQuery] = useWindowQueryUpdate(navigate)

  return <QueryStringContext.Provider value={[query, setQuery]}>{children}</QueryStringContext.Provider>
}

export default QueryStringProvider
